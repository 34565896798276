/*
 * @Author: KJ_FRONT
 * @Description: 
 */
import ajax from './axios'
// 获取上次保养周期表
export const getLastMaintenancePlan = params => {
  return ajax.post('/garage/maintenance/last/plan', params)
}

// 获取保养周期表
export const getMaintenancePlan = params => {
  return ajax.post('/garage/maintenance/correctTime/plan', params)
}

// 省市区三级数据
export const getCascadePicker = () => {
  return ajax.get('/garage/position/area/all')
}
// 主修车型列表
export const getCarMajorModelList = () => {
  return ajax.get('/garage/carMajorModel/list')
}
// 获取可用的业务列表
export const getBusinessList = () => {
  return ajax.get('/user/garage/business/list')
}
