<template>
  <div class="wx-wrapper" style="height: 100vh;position:relative">
    <div class="vehicles-tab" v-if="myVehicles && myVehicles.length > 1">
      <span
        :class="vehicle.code === currentVehicle ? 'selected' : ''"
        v-for="(vehicle, index) in myVehicles"
        :key="index"
        @click="selectVehicle(vehicle)"
      >{{vehicle.code}}</span>
    </div>
    <div
      class="maintain-tips"
      v-if="myVehicles && myVehicles.length > 0 && lastMaintenanceRecord.lastMileageId"
    >
      <h1>根据上次进厂里程已做{{lastMaintenanceRecord.lastMileageYear}}/{{lastMaintenanceRecord.lastMileage}}保养计划</h1>
      <div>
        <span>上次进厂里程：{{lastMaintenanceRecord.lastMaintainMileage}}</span>
        <span>上次进厂时间：{{lastMaintenanceRecord.lastMaintenanceTime}}</span>
      </div>
    </div>
    <div class="legend">
      <span>
        <i class="dot-left"></i>已做的项目
      </span>
      <span>
        <i class="dot-right"></i>推荐的项目
      </span>
    </div>
    <remote-script src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js" />
    <el-table
      ref="table"
      class="maintenance-table"
      :data="tableData"
      style="width: 100%"
      :height="tableHeight"
      :border="true"
      row-class-name="table-row"
      empty-text
      @cell-click="onCellClicked"
    >
      <el-table-column
        fixed
        prop="name"
        label="保养项目"
        :width="rowWidth1"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <span :class="{'long-text': scope.row.name.length > 5}">{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <label v-if="tableType===1">
        <el-table-column
          :class-name="columnClass(data)"
          v-for="(data, index) in maintenancePlan.mileageList"
          :key="index"
          :prop="`mileage-${data.id}`"
          :label="`${data.mileage}km ${data.month/12}年`"
          header-align="center"
          align="center"
          :width="rowWidth2"
          :render-header="renderHeader"
        >
          <template slot-scope="scope">
            <span class="maintenance-item" :class="cellClass(scope.row, data.id)" />
          </template>
        </el-table-column>
      </label>
      <label name="third" v-else>
        <el-table-column
          :class-name="columnClass2(data)"
          v-for="(data, index) in maintenancePlan.mileageList"
          :key="index"
          :prop="`mileage-${data.mileage}`"
          :label="`${data.mileage}km ${data.month/12}年`"
          header-align="center"
          align="center"
          :width="rowWidth2"
          :render-header="renderHeader"
        >
          <template slot-scope="scope">
            <span class="maintenance-item" :class="cellClass(scope.row, data.mileage)" />
          </template>
        </el-table-column>
      </label>
    </el-table>
    <div v-if="guide.item && guideVisible" class="guide">
      <div class="mask" @click="hideGuide" />
      <div class="content" :style="{'margin-top': guide.marginTop + 'px'}">
        <div class="item">
          <span :class="{'long-text': guide.item.length > 5}">{{guide.item}}</span>
          <img src="~images/other/hand-cursor.png" />
        </div>
        <div class="tips">
          <img src="~images/other/maintenance-guide.png" />
          <span>请点击具体的保养项目，查看相关保养说明</span>
        </div>
      </div>
    </div>
    <!-- 浮层 -->
    <div :class="showDialog ? 'free-dialog-show' : '' ">
      <div class="free-dialog-mask" @click="cancelDialog" />
      <div class="free-dialog-container">
        <div class="free-dialog-main">
          <div class="free-dialog-title">
            <span>原厂技术参数</span>
            <span class="close-dialog" @click="cancelDialog">关闭</span>
          </div>
          <div v-if="currentVehicleDetail" class="main-name">{{currentVehicleDetail.model}}</div>
          <div class="scroll-body">
            <div class="main-item" v-for="(item,index) in skillDetailList" :key="index">
              <div class="item-name">
                <span>{{item.kpsName}}</span>
              </div>
              <div class="item-detail">
                <div>
                  <span>规格：</span>
                  {{item.mcomments}}
                </div>
                <div>
                  <span>用量：</span>
                  {{item.muserLevel}}{{item.munit}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 原厂技术参数 -->
    <div
      v-show="hasTecParaBtn"
      @click="showTecParaBtn"
      class="tec-para-btn"
      :class="{'tec-para-btn-scrolling':isTabelScroll}"
    >
      <img :src="tecPara" alt />
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import { vehicleList, factoryParams } from 'api/vehicle.js'
import { getLastMaintenancePlan } from 'api/item'
import { setLocalStorage, getLocalStorage } from 'utils/store'
import tecParaImg from '@/assets/images/vx/tec-para.png'
export default {
  mixins: [mixin],
  data () {
    return {
      mileageSlider: 0,
      myVehicles: [],
      lastMaintenanceRecord: {},
      currentVehicle: '',
      guide: {},
      guideVisible: false,
      companyId: null,
      rowWidth1: '',
      rowWidth2: '',
      tableType: 1,
      currentVehicleDetail: '',
      skillDetailList: [],
      showDialog: false, // 原厂技术参数弹窗
      tecPara: tecParaImg,
      isTabelScroll: false,
      tableScrollTop: 0,
      hasTecParaBtn: false, // 是否含有原厂技术参数表
    }
  },
  watch: {
    tableScrollTop (newVal, oldVal) {
      let $this = this;
      setTimeout(() => {
        if (newVal == this.$refs.table.bodyWrapper.scrollTop) {
          console.log('滚动结束');
          $this.isTabelScroll = false;
        }
      }, 20);

      console.log('滚动开始');
      this.getTableScroll();
    }
  },
  methods: {
    showTecParaBtn () {
      this.showDialog = true
    },
    // 显示报表推荐项样式（灰色圆形）
    itemMatched (row, key) {
      return row[key] === 1
    },
    // 判断是否有上次保养计划
    planRecommanded (id) {
      return id === this.lastMaintenanceRecord.lastMileageId
    },
    // 判断上次保养计划的配件
    inLastRecord (id, columnId) {
      if (!this.lastMaintenanceRecord.lastMaintenanceIdList || this.lastMaintenanceRecord.lastMaintenanceIdList.length <= 0) {
        return false
      }
      if (columnId !== this.lastMaintenanceRecord.lastMileageId) {
        return false
      }
      return this.lastMaintenanceRecord.lastMaintenanceIdList.indexOf(id) >= 0
    },
    // 表格样式
    columnClass (data) {
      return (this.planRecommanded(data.id) ? 'column-recommanded' : '') + ' ' + (`${data.mileage}km`.length >= 8 ? 'long-text' : '')
    },
    columnClass2 (data) {
      return (this.planRecommanded(data.mileage) ? 'column-recommanded' : '') + ' ' + (`${data.mileage}km`.length >= 8 ? 'long-text' : '')
    },
    // 单元格样式
    cellClass (rowData, columnId) {
      return [{ 'matched': this.itemMatched(rowData, `mileage-${columnId}`) }, { 'recommanded': this.planRecommanded(columnId) }, { 'in-last-records': this.inLastRecord(rowData.id, columnId) }]
    },
    // 重设表头
    renderHeader (h, columnData) {
      const data = this.maintenancePlan.mileageList[columnData.$index - 1]
      if (this.maintenancePlan.type === 1) {
        return h('div', {
          'class': {
            'header-column': true
          }
        }, [h('p', `${data.month / 12}年`), h('p', `${data.mileage / 10000}万公里`)])
      } else {
        if (data.showType) {
          return h('div', {
            'class': {
              'header-column': true
            }
          }, [h('p', `${data.showText}`), h('p', `保养计划`)])
        }
        return h('div', {
          'class': {
            'header-column': true
          }
        }, [h('p', `${data.mileage / 10000}万公里`)])
      }


    },
    onCellClicked (row, column, cell, event) {
      if (column.property !== 'name') {
        return
      }
      if (!this.maintenancePlan.maintenanceVOList) {
        return
      }
      let url = ''
      for (let item of this.maintenancePlan.maintenanceVOList) {
        if (item.maintenance.id !== row.id) {
          continue
        }
        url = item.maintenance.url
        break
      }
      if (!url) {
        return
      }
      // eslint-disable-next-line
      wx.miniProgram.navigateTo({
        url: `/pages/maintenance/detail/detail?url=${url}`
      })
    },
    // 请求原厂技术参数信息
    getfactoryParamsOFTecParaBtn () {
      if (!this.currentVehicleDetail || !this.currentVehicleDetail.modelId || !this.currentVehicleDetail.vendor) {
        return
      }
      const params = {
        carModelId: this.currentVehicleDetail.modelId,
        vendor: this.currentVehicleDetail.vendor
      }
      factoryParams(params).then((res) => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }
        if (!res.data.data || res.data.data.length === 0) {
          return
        }
        this.hasTecParaBtn = true
        this.skillDetailList = res.data.data
      })
    },
    // 获取车型信息
    getMyVehicles () {
      vehicleList(JSON.stringify({})).then((res) => {
        if (res.data.result !== 0) {
          return false
        }
        this.myVehicles = res.data.data
        console.log('获取车型信息');
        console.log(this.myVehicles);
        this.currentVehicleDetail = this.myVehicles[0]
        this.getfactoryParamsOFTecParaBtn();
        this.$emit('got-vehicles')
        return true
      }).catch(err => {
        console.log(err)
        return false
      })
    },
    // 获取上次保养计划（停止使用）
    getLastPlan (plateNumber) {
      const toast = this.$createToast({
        txt: '加载中...',
        type: 'loading',
        mask: true,
        time: 3000
      })

      toast.show()
      this.lastMaintenanceRecord = {}
      this.calcElementsPosition()

      getLastMaintenancePlan({ code: plateNumber, companyId: this.companyId }).then(res => {
        toast.hide()
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }

        let data = res.data.data
        this.lastMaintenanceRecord = {
          lastMaintenanceIdList: data.lastMaintenanceIdList,
          lastMaintenanceTime: data.lastMaintenanceTime ? data.lastMaintenanceTime.substring(0, 10) : '无',
          lastMileageId: data.lastMileageId,
          lastMaintainMileage: data.lastMaintainMileage ? `${data.lastMaintainMileage}公里` : '无'
        }

        let index = 0
        this.maintenancePlan.mileageList.forEach(e => {
          if (e.id === this.lastMaintenanceRecord.lastMileageId) {
            this.lastMaintenanceRecord.lastMileage = `${e.mileage / 10000}万公里`
            this.lastMaintenanceRecord.lastMileageYear = `${e.month / 12}年`
            this.lastMaintenanceRecord.lastMileageIndex = index
          }

          index++
        })

        this.$emit('got-last-record')
      })
    },
    // 若是有多个车辆，可以进行选择
    selectVehicle (vehicle) {
      if (this.currentVehicle === vehicle.code) {
        return
      }
      this.currentVehicleDetail = vehicle
      this.currentVehicle = vehicle.code
      // this.getLastPlan(this.currentVehicle)
      this.getPlan(this.currentVehicle)
      this.isFactoryParams()
      this.hasTecParaBtn = false;
      this.getfactoryParamsOFTecParaBtn();
    },
    // 计算表格高度
    calcElementsPosition () {
      const tabHeight = 80
      const recordSummaryHeight = 140
      const legendHeight = 64
      let height = document.documentElement.clientHeight || document.body.clientHeight
      let offset = legendHeight
      offset = this.myVehicles && this.myVehicles.length > 1 ? (tabHeight + offset) : offset
      offset = this.lastMaintenanceRecord.lastMileageId ? (recordSummaryHeight + offset) : offset
      this.tableHeight = height - this.ratioSize(offset)

      this.guide.marginTop = this.ratioSize(offset + 90 + 70)
    },
    // 保养引导
    hideGuide () {
      console.log(222)
      this.guideVisible = false
      this.isFactoryParams()
    },
    // 关闭原厂技术参数的浮层
    cancelDialog () {
      this.showDialog = false
      const vehicleStorage = getLocalStorage('storageList')
      if (!vehicleStorage) {
        const arrayList = []
        arrayList.push(this.currentVehicle)
        setLocalStorage('storageList', arrayList)
        return
      }
      const storageArray = JSON.parse(vehicleStorage)
      const isRepeat = storageArray.some((res) => {
        return res === this.currentVehicle
      })
      if (isRepeat) {
        return
      }
      storageArray.push(this.currentVehicle)
      setLocalStorage('storageList', storageArray)
    },
    // 判断是否弹出原厂技术参数弹窗
    isFactoryParams () {
      const vehicleStorage = getLocalStorage('storageList')
      console.log('kkkk', vehicleStorage)
      if (!vehicleStorage) {
        this.getfactoryParams()
      } else {
        const storageArray = JSON.parse(vehicleStorage)
        console.log('判断是否弹出原厂技术参数弹窗', storageArray);
        const isHiden = storageArray.some((res) => {
          return res === this.currentVehicle
        })
        if (!isHiden) {
          this.getfactoryParams()
        }
      }
    },
    // 获取原厂技术参数
    getfactoryParams () {
      console.log(this.currentVehicleDetail, 'currentVehicleDetail')
      if (!this.currentVehicleDetail || !this.currentVehicleDetail.modelId || !this.currentVehicleDetail.vendor) {
        return
      }
      const params = {
        carModelId: this.currentVehicleDetail.modelId,
        vendor: this.currentVehicleDetail.vendor
      }
      factoryParams(params).then((res) => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }
        if (!res.data.data || res.data.data.length === 0) {
          return
        }
        this.showDialog = true
        this.skillDetailList = res.data.data
      })
    },
    // 页面滚动
    getTableScroll () {
      this.isTabelScroll = true;
    }
  },
  beforeMount () {
    this.sizeRatio = (document.documentElement.clientWidth || document.body.clientWidth) / 750
    this.calcElementsPosition()
  },
  mounted () {
    this.companyId = this.$route.query['companyId']
    // 获取引导提示数据
    this.$on('plan-updated', () => {
      this.guide.item = this.maintenancePlan.maintenanceVOList[1].maintenance.name
    })
    // 车辆数据获取成功后更新保养周期表数据
    this.$on('got-vehicles', () => {
      if (this.myVehicles.length <= 0) {
        this.getPlan()
        return
      }

      this.currentVehicle = this.myVehicles[0].code
      // this.getLastPlan(this.currentVehicle)
      this.getPlan(this.currentVehicle)
      // 第一次获取原厂技术参数
      // this.getfactoryParams()
    })
    // 由于获取上次保养计划和当前保养计划数据接口合并，该方法停用
    this.$on('got-last-record', () => {
      this.$refs.table.bodyWrapper.scrollLeft = this.sizeRatio * 120 * (this.lastMaintenanceRecord.lastMileageIndex > 0 ? this.lastMaintenanceRecord.lastMileageIndex - 1 : this.lastMaintenanceRecord.lastMileageIndex)
      this.calcElementsPosition()
    })
    // 用于展示引导提示
    if (this.$route.query['showGuide']) {
      this.guideVisible = this.$route.query['showGuide'] === '1'
    }
    // 获取车辆数据
    this.getMyVehicles()
    // 监听表格的滚动
    let $this = this;
    this.$refs.table.bodyWrapper.addEventListener('scroll', function () {
      console.log('表格的滚动');
      $this.tableScrollTop = this.scrollTop;
    });
  },
  beforeDestroy () {
    // 移除表格监听事件
    // this.$refs.table.bodyWrapper.removeEventListener('scroll')
  }
}
</script>

<style lang="scss" scoped>
@import '~styles/index';
// 原厂技术参数按钮
.tec-para-btn {
  position: absolute;
  bottom: px2rem(121px);
  width: px2rem(214px);
  height: px2rem(114px);
  right: px2rem(0px);
  transition: right 0.5s;
  img {
    width: 100%;
  }
}
// 原厂技术参数按钮 滚动状态
.tec-para-btn-scrolling {
  right: px2rem(-124px);
}

.input-tip {
  width: 100%;
  height: px2rem(40px);
  line-height: px2rem(40px);
  margin-top: px2rem(15px);
  font-size: px2rem(28px);
  font-weight: bold;
  color: #ff9240;
  text-align: center;
}

.mileage {
  width: 100%;
  height: px2rem(50px);
  line-height: px2rem(50px);
  margin-top: px2rem(15px);
  font-size: px2rem(36px);
  font-weight: bold;
  color: #363636;
  text-align: center;
}

.mileage-slider {
  height: px2rem(50px);
  margin: px2rem(27px) px2rem(60px) 0;
}

.mileage-label {
  height: px2rem(24px);
  margin: px2rem(-4px) px2rem(60px) 0;
  color: #4081d6;
  font-size: px2rem(24px);
  display: flex;
  justify-content: space-between;
}

.mileage-tooltip {
  background: #ff9240 !important;
}

.table-row {
  height: 10px;
}

.maintenance-item {
  display: inline-block;
  width: px2rem(20px);
  height: px2rem(20px);
  border-radius: 50%;
}

.matched {
  background: #999999;
}

.recommanded.in-last-records {
  background: #ff9240;
}

/*保养手册2018-11-08*/
.vehicles-tab {
  background: #f6f6f6;
  display: flex;
  justify-content: space-around;
  height: px2rem(80px);
}
.vehicles-tab span {
  font-size: px2rem(28px);
  text-align: center;
  display: inline-block;
  padding-bottom: px2rem(6px);
  margin: auto 0;
}
.vehicles-tab span.selected {
  border-bottom: px2rem(6px) solid #ff9d4d;
  color: #000;
  font-weight: 700;
}
.maintain-tips {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #fff;
  width: px2rem(750px);
  height: px2rem(140px);
  color: #363636;
}
.maintain-tips h1 {
  display: block;
  font-size: px2rem(28px);
  margin-bottom: px2rem(15px);
}
.maintain-tips div {
  font-size: px2rem(24px);
  color: #999;
}
.maintain-tips > div span:first-child {
  padding-right: px2rem(22px);
}
.legend {
  width: px2rem(750px);
  text-align: center;
  font-size: px2rem(24px);
  height: px2rem(64px);
  line-height: px2rem(64px);
  background: #f9fdff;
  border-top: 1px solid #e3ecf6;
  border-bottom: 1px solid #e3ecf6;
}
.legend span:first-child {
  margin-right: px2rem(131px);
  color: #ff9240;
}
.dot-left {
  width: px2rem(20px);
  height: px2rem(20px);
  background: #ff9240;
  border-radius: 50%;
  display: inline-block;
  margin-right: px2rem(20px);
}
.dot-right {
  width: px2rem(20px);
  height: px2rem(20px);
  background: #999999;
  border-radius: 50%;
  display: inline-block;
  margin-right: px2rem(20px);
}

.guide {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: transparent;

  .mask {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 1);
    opacity: 0.4;
    z-index: 1001;
  }

  .content {
    z-index: 1001;
    position: fixed;

    .item {
      display: flex;
      align-items: center;
      position: relative;

      span {
        display: inline-block;
        width: px2rem(151px);
        height: px2rem(64px);
        line-height: px2rem(64px);
        font-size: px2rem(24px);
        text-align: center;
        font-weight: 400;
        color: rgba(54, 54, 54, 1);
        background: #ffffff;
        border-radius: px2rem(6px);

        &.long-text {
          line-height: px2rem(32px);
          font-size: px2rem(20px);
          padding: 0 px2rem(10px);
        }
      }

      span::after {
        content: '';
        width: px2rem(4px);
        height: px2rem(64px);
        background: #ffffff;
        position: absolute;
        left: px2rem(65px);
        top: px2rem(64px);
      }

      img {
        position: absolute;
        width: px2rem(66px);
        height: px2rem(55px);
        left: px2rem(130px);
        top: px2rem(32px);
      }
    }

    .tips {
      margin-top: px2rem(64px);
      margin-left: px2rem(16px);
      display: flex;
      align-items: center;
      height: px2rem(208px);
      background: rgba(255, 255, 255, 1);
      border-radius: px2rem(20px);
      padding: 0 px2rem(54px) 0 px2rem(24px);

      span {
        display: inline-block;
        width: px2rem(365px);
        font-size: px2rem(32px);
        font-weight: 400;
        color: rgba(54, 54, 54, 1);
        line-height: px2rem(45px);
      }

      img {
        margin-left: px2rem(5px);
        width: px2rem(131px);
        height: px2rem(125px);
      }
    }
  }
}

/* 浮层 */
.free-dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}

.free-dialog-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: px2rem(750px);
  height: px2rem(916px);
  background: white;
  transform: translateY(150%);
  transition: all 0.4s ease;
  z-index: 11;
}

.free-dialog-show .free-dialog-container {
  transform: translateY(0);
}

.free-dialog-show .free-dialog-mask {
  display: block;
}

/* 内容 */
.scroll-body {
  height: px2rem(703px);
  overflow-x: scroll;
}
.free-dialog-main .main-name {
  display: flex;
  justify-content: center;
  padding: px2rem(37px) px2rem(30px);
}

.free-dialog-main .free-dialog-title {
  height: px2rem(45px);
  font-size: px2rem(32px);
  padding: px2rem(30px);
  color: #363636;
  text-align: center;
  border-bottom: px2rem(1px) solid rgba(228, 228, 228, 1);
}
.free-dialog-title .close-dialog {
  color: #999999;
  float: right;
}

.free-dialog-main .main-name {
  padding: px2rem(45px) 0 px2rem(30px) 0;
  height: px2rem(32px);
  line-height: px2rem(32px);
  font-size: px2rem(28px);
  font-weight: 600;
  color: #363636;
  text-align: center;
}
.free-dialog-main .main-item {
  padding: 15px px2rem(30px);
  height: px2rem(68px);
  font-size: px2rem(24px);
  display: flex;
}

.main-item .item-name {
  width: px2rem(167px);
  height: px2rem(40px);
  position: relative;
  margin-right: px2rem(20px);
}
.item-name image {
  width: 100%;
  height: 100%;
}
.item-name span {
  width: px2rem(155px);
  height: px2rem(40px);
  color: #ff9d4d;
  font-weight: 600;
  line-height: px2rem(40px);
  text-align: center;
  background: url('../../../../../assets/images/other/yellow-bg.png') no-repeat;
  background-size: px2rem(155px) px2rem(40px);
  position: absolute;
  top: 0;
  left: px2rem(12px);
  z-index: 1;
}
.main-item .item-detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item-detail span {
  font-weight: 600;
}
</style>
