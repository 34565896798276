import {
  getMaintenancePlan
} from 'api/item'
import {
  setCookie
} from 'utils/store'

export default {
  data () {
    return {
      mileage: 0,
      tableHeight: 0,
      recommandedPlanIndex: 0,
      recommandedPlanMileage: 0,
      recommandedPlanYear: '',
      maintenancePlan: {},
      maxMileage: 200000,
      sizeRatio: 0,
      tableData: []
    }
  },
  methods: {
    // 屏幕尺寸适配
    ratioSize (size) {
      return size * this.sizeRatio
    },
    // 获取保养周期表数据
    getPlan (plateNumber) {
      this.$createToast({
        txt: '加载中...',
        type: 'loading',
        mask: true,
        time: 2000
      }).show()
      let params = {}
      if (plateNumber) {
        params = {
          carCode: plateNumber,
          companyId: this.companyId
        }
      }

      getMaintenancePlan(params).then((res) => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }
        let tableArray = []
        let data = res.data.data
        this.maintenancePlan = data
        this.tableType = data.type
        // 上次保养计划数据处理
        this.lastMaintenanceRecord = {
          lastMaintenanceIdList: data.lastMaintenanceIdList,
          lastMaintenanceTime: data.lastMaintenanceTime ? data.lastMaintenanceTime.substring(0, 10) : '无',
          lastMileageId: data.lastMileageId,
          lastMaintainMileage: data.lastMaintainMileage ? `${data.lastMaintainMileage}公里` : '无'
        }
        // this.maintenancePlan.type值：2为正时数据，1为客佳数据
        if (this.maintenancePlan.type === 1) {
          this.rowWidth1 = this.ratioSize(151)
          this.rowWidth2 = this.ratioSize(120)
        } else {
          this.rowWidth1 = ''
          this.rowWidth2 = ''
        }
        let index = 0
        let CurrentIndex = 0
        const _this = this
        if (this.tableType === 1) {
          this.maintenancePlan.maintenanceVOList.forEach(element => {
            let item = {}
            item.name = element.maintenance.name
            item.index = index
            item.id = element.maintenance.id
            if (plateNumber) {
              _this.maintenancePlan.mileageList.forEach(mileage => {
                let ls = _this.maintenancePlan.maintenance2mileageMap[mileage.mileage]
                let recommanded = 0
                for (let e of ls) {
                  // 根据ID值进行匹配是否为推荐项目
                  recommanded = e.maintenanceId === element.maintenance.id ? 1 : 0
                  if (recommanded) {
                    break
                  }
                }
                this.$set(item, `mileage-${mileage.id}`, recommanded)
                if (mileage.mileage > this.maxMileage) {
                  this.maxMileage = mileage.mileage
                }
                if (mileage.id === this.lastMaintenanceRecord.lastMileageId) {
                  this.lastMaintenanceRecord.lastMileage = `${mileage.mileage / 10000}万公里`
                  this.lastMaintenanceRecord.lastMileageYear = `${mileage.month / 12}年`
                  this.lastMaintenanceRecord.lastMileageIndex = CurrentIndex
                }
                CurrentIndex++
              })
            }
            tableArray.push(item)
            index++
            this.$emit('got-last-record')
          })
        } else {
          this.maintenancePlan.mileageList.unshift({
            "mileage": this.maintenancePlan.lastMileageId,
            "showText": this.maintenancePlan.lastMaintenanceTime.substring(0, 10),
            "showType": true // 展示插入的表头
          })
          this.maintenancePlan.maintenanceVOList.forEach(element => {
            let item = {}
            item.name = element.maintenance.name
            item.index = index
            item.id = element.maintenance.id
            _this.maintenancePlan.mileageList.forEach(mileage => {
              let ls = _this.maintenancePlan.maintenance2mileageMap[mileage.mileage]
              if (!ls) {
                return
              }
              let recommanded = 0
              for (let e of ls) {
                recommanded = e.maintenanceId === element.maintenance.id ? 1 : 0
                if (recommanded) {
                  break
                }
              }
              this.$set(item, `mileage-${mileage.mileage}`, recommanded)
              if (mileage.mileage > this.maxMileage) {
                this.maxMileage = mileage.mileage
              }
              if (mileage.id === this.lastMaintenanceRecord.lastMileageId) {
                this.lastMaintenanceRecord.lastMileage = `${mileage.mileage / 10000}万公里`
                this.lastMaintenanceRecord.lastMileageYear = `${mileage.month / 12}年`
                this.lastMaintenanceRecord.lastMileageIndex = CurrentIndex
              }
              CurrentIndex++
            })
            tableArray.push(item)
            index++
            this.$emit('got-last-record')
          })
        }
        this.tableData = tableArray
        this.$emit('plan-updated')
        this.$refs.table.bodyWrapper.scrollLeft = this.sizeRatio * 120 * (this.lastMaintenanceRecord.lastMileageId > 0 ? this.lastMaintenanceRecord.lastMileageId - 1 : this.lastMaintenanceRecord.lastMileageId)
      })
    }
  },
  computed: {
    recommandedPlanId () {
      if (!this.maintenancePlan.mileageList) {
        return -1
      }

      let planId = -1
      let index = 0
      if (this.mileage > this.maxMileage) {
        index = this.maintenancePlan.mileageList.length - 1
        let mileage = this.maintenancePlan.mileageList[index]
        planId = mileage.id
        this.recommandedPlanIndex = index
        this.recommandedPlanMileage = mileage.mileage
        this.recommandedPlanYear = `${mileage.month / 12}年`
        return planId
      }

      let diff = -1
      this.maintenancePlan.mileageList.forEach(e => {
        let d = e.mileage - this.mileage
        if ((d < diff || diff === -1) && d >= 0) {
          diff = d
          planId = e.id
          this.recommandedPlanIndex = index
          this.recommandedPlanMileage = e.mileage
          this.recommandedPlanYear = `${e.month / 12}年`
        }

        index++
      })

      return planId
    }
  },
  mounted () {
    if (this.$route.query['token']) {
      setCookie('token', this.$route.query['token'], 1)
    }
  }
}